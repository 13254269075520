.home-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.home-grid-column {
    padding: 1rem;
}
.home-card-2024 {
    border-radius: 1.5rem;
    border-color: var(--gray-primary);
    border-width: 3px;
    padding: 2.5rem;
    align-items: center;
    row-gap: 2.5rem;
    height: 100%;
}
.home-card-title {
    text-transform: uppercase;
    font-weight: 700;
    margin: 0;
    padding: 0;
    color: var(--text-primary);
}
.home-card-text {
    color: var(--text-primary);
    margin: 0;
    padding: 0;
}
.home-card-image {
    display: block;
    height: 10rem;
}
.home-card-description {
    height: 120px;
    display: flex;
    align-items: center;
}
.home-card-list {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
}
.home-card-list-item {
    display: flex;
    column-gap: 1rem;
    align-items: baseline;
    text-align: left;
}

.home-carousel-container {
    padding: 5rem 0;
}

.carousel-control-next-icon {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNicgZmlsbD0nIzRlNTE4MDsnPjxwYXRoIGQ9J000LjY0NiAxLjY0NmEuNS41IDAgMCAxIC43MDggMGw2IDZhLjUuNSAwIDAgMSAwIC43MDhsLTYgNmEuNS41IDAgMCAxLS43MDgtLjcwOEwxMC4yOTMgOCA0LjY0NiAyLjM1NGEuNS41IDAgMCAxIDAtLjcwOHonIHN0eWxlID0gImZpbGw6ICM0ZTUxODA7IiAvPjwvc3ZnPg==);
    width: 2rem;
    height: 2rem;
}
.carousel-control-prev-icon {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNicgZmlsbD0nI2ZmZic+PHBhdGggZD0nTTExLjM1NCAxLjY0NmEuNS41IDAgMCAxIDAgLjcwOEw1LjcwNyA4bDUuNjQ3IDUuNjQ2YS41LjUgMCAwIDEtLjcwOC43MDhsLTYtNmEuNS41IDAgMCAxIDAtLjcwOGw2LTZhLjUuNSAwIDAgMSAuNzA4IDB6JyBzdHlsZSA9ICJmaWxsOiAjNGU1MTgwOyIgLz48L3N2Zz4=);
    width: 2rem;
    height: 2rem;
}

.home-pic-title {
    margin: auto;
    padding-top: 2.5%;
    text-align: center;
    font-size: 3rem;
}

.home-pic-text {
    padding-bottom: 5%;
    padding-right: 5%;
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 2.6rem;
}

/* small screens */
@media screen and (max-width: 1200px) {
    .home-grid-container {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 1320px) {
    .home-card-description {
        height: 135px;
    }
}

@media screen and (max-width: 768px) {
    .home-grid-container {
        grid-template-columns: 1fr;
    }
    .home-card-description {
        height: auto;
    }
}

@media screen and (max-width: 634px) {
    .home-pic-text, .home-pic-title {
        display: none;
    }
}